import { template as template_df240000f8164395b22f818afe7695f2 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import NotificationsTracking from "discourse/components/notifications-tracking";
import { topicLevels } from "discourse/lib/notification-levels";
import { i18n } from "discourse-i18n";
export default class TopicNotificationsTracking extends Component {
    get suffix() {
        return this.args.topic?.archetype === "private_message" ? "_pm" : "";
    }
    static{
        template_df240000f8164395b22f818afe7695f2(`
    <NotificationsTracking
      @onChange={{@onChange}}
      @levelId={{@levelId}}
      @showCaret={{@showCaret}}
      @showFullTitle={{@showFullTitle}}
      @prefix="topic.notifications"
      @title={{i18n "topic.notifications.title"}}
      class="topic-notifications-tracking"
      @levels={{topicLevels}}
      @suffix={{this.suffix}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
