import { template as template_5e9853ac69024f149237593df5ca0f46 } from "@ember/template-compiler";
const FKLabel = template_5e9853ac69024f149237593df5ca0f46(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
