import { template as template_de21a0ab5a9c4e82b56f5aa8d6c7f879 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_de21a0ab5a9c4e82b56f5aa8d6c7f879(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
