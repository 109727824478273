import { template as template_a93f5213a4b54bf89aa60192cd9d7db5 } from "@ember/template-compiler";
const FKText = template_a93f5213a4b54bf89aa60192cd9d7db5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
