import { template as template_c698ff0e162a4d5ca439ff9d0f8eddab } from "@ember/template-compiler";
const FKControlMenuContainer = template_c698ff0e162a4d5ca439ff9d0f8eddab(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
